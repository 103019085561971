<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
              >
                <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

            </div>
          </b-col>
        </b-row>

        <b-card
            v-show="advanced_search_modal"
        >
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition"
          >
            <b-row>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品69码:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.code"
                      name="statementNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>
              <b-col
                  cols="12"
                  md="4"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称:"
                    label-for="order_no"
                    label-size="sm"
                >
                  <b-form-input
                      v-model="condition.name"
                      name="relationalNo"
                      class="d-inline-block mr-1"
                      size="sm"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="12"
                  class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="advanced_search"
                >
                  <span class="align-right">关闭</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >
        <template #row-details="row">
          <!--          <b-card v-for="i in row.item.ext.items" :key="i.name">-->
          <b-card>
            <div>
              <b-table striped hover :items="row.item.ext.priceActivity" :fields="fields" show-empty empty-text="未找到记录">
                <template #cell(price)="data">
                  {{ data.item.activity_price }}
                </template>
                <template #cell(store)="data">
                  {{ getCodeLabel("store", data.item.target_id) }}
                </template>
                <template #cell(date)="data">
                  {{ toDate(data.item.start_date) }} 至 {{ toDate(data.item.end_date) }}
                </template>
                <template #cell(status)="data">
                  <b-badge
                      pill
                      :variant="`light-${getCodeColor('activity_status', data.item.status)}`"
                  >
                    {{ getCodeLabel("activity_status", data.item.status) }}
                  </b-badge>
                </template>
              </b-table>
            </div>
          </b-card>
        </template>
        <!-- Column: Type -->
        <template #cell(id)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="15"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          [#{{ data.item.id }}]
        </template>

        <!-- Column: Type -->
        <template #cell(add_time)="data">
          {{ toDate(data.item.add_time) }}
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="show(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>

        <b-modal
            id="modal-price-guide-offline"
            ok-only
            ok-title="取消"
            centered
            size="lg"
            title="编辑价格"
            ref="modalPriceGuideOffline"
            @ok="refetchData"
        >
          <b-row>
            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="商品69码"
                  label-for="cost_reference"
                  label-size="sm"
                  class=""
              >
                <b-form-input
                    size="sm"
                    v-model="priceGuideOffline.code"
                    readonly=""
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="商品名称"
                  label-for="cost_reference"
                  label-size="sm"
              >
                <b-form-input
                    size="sm"
                    v-model="priceGuideOffline.name"
                    readonly=""
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="成本价"
                  label-for="cost_reference"
                  label-size="sm"
              >
                <b-form-input
                    size="sm"
                    v-model="priceGuideOffline.avg_price"
                    readonly=""
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="批发"
                  label-for="cost_reference"
                  label-size="sm"
              >
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="priceGuideOffline.kind1"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="大型卖场"
                  label-for="cost_reference"
                  label-size="sm"
              >
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="priceGuideOffline.kind2"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="连锁超市"
                  label-for="cost_reference"
                  label-size="sm"
              >
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="priceGuideOffline.kind3"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="中型超市"
                  label-for="cost_reference"
                  label-size="sm"
              >
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="priceGuideOffline.kind4"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="个人用户"
                  label-for="cost_reference"
                  label-size="sm"
              >
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="priceGuideOffline.kind5"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="便利店"
                  label-for="cost_reference"
                  label-size="sm"
              >
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="priceGuideOffline.kind6"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="团购"
                  label-for="cost_reference"
                  label-size="sm"
              >
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="priceGuideOffline.kind7"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="特通"
                  label-for="cost_reference"
                  label-size="sm"
              >
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="priceGuideOffline.kind8"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="电商"
                  label-for="cost_reference"
                  label-size="sm"
              >
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="priceGuideOffline.kind9"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="餐饮门店"
                  label-for="cost_reference"
                  label-size="sm"
              >
                <b-form-input
                    size="sm"
                    type="number"
                    v-model="priceGuideOffline.kind10"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-col
              cols="12"
              class="mt-50"
          >
            <b-button
                variant="primary"
                class="mr-1"
                @click="save"
            >
              保存
            </b-button>
          </b-col>
        </b-modal>

      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  isEmpty
} from '@core/utils/filter'
import priceguideofflineUseList from './priceguideofflineUseList'
import priceguideofflineStore from './priceguideofflineStore'
import Ripple from "vue-ripple-directive";
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        {key: 'price', label: '活动价格'},
        {key: 'store', label: '销售团队'},
        {key: 'date', label: '活动有效期'},
        {key: 'status', label: '状态'},
      ],
      advanced_search_modal: false,
      priceGuideOffline: {}
    }
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('priceguideoffline/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.condition = {}
      this.refetchData();
    },
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('priceguideoffline')) store.registerModule('priceguideoffline', priceguideofflineStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('priceguideoffline')) store.unregisterModule('priceguideoffline')
    })

    const advanced_search = function () {
      this.advanced_search_modal = this.advanced_search_modal ? false : true;
    }

    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.condition[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const show = function (params) {
      this.priceGuideOffline = params
      this.$refs['modalPriceGuideOffline'].show()
    }

    const save = function () {
      if (isEmpty(this.priceGuideOffline.kind1)) {
        toast.error("请填写批发价格!")
        return false
      }
      if (isEmpty(this.priceGuideOffline.kind2)) {
        toast.error("请填写大型卖场价格!")
        return false
      }
      if (isEmpty(this.priceGuideOffline.kind3)) {
        toast.error("请填写连锁超市价格!")
        return false
      }
      if (isEmpty(this.priceGuideOffline.kind4)) {
        toast.error("请填写中型超市价格!")
        return false
      }
      if (isEmpty(this.priceGuideOffline.kind5)) {
        toast.error("请填写个人用户价格!")
        return false
      }
      if (isEmpty(this.priceGuideOffline.kind6)) {
        toast.error("请填写便利店价格!")
        return false
      }
      if (isEmpty(this.priceGuideOffline.kind7)) {
        toast.error("请填写团购价格!")
        return false
      }
      if (isEmpty(this.priceGuideOffline.kind8)) {
        toast.error("请填写特通价格!")
        return false
      }
      if (isEmpty(this.priceGuideOffline.kind9)) {
        toast.error("请填写电商价格!")
        return false
      }
      if (isEmpty(this.priceGuideOffline.kind10)) {
        toast.error("请填写餐饮门店价格!")
        return false
      }
      if (parseFloat(this.priceGuideOffline.kind1) < 0 || parseFloat(this.priceGuideOffline.kind2)< 0 || parseFloat(this.priceGuideOffline.kind3)< 0 || parseFloat(this.priceGuideOffline.kind4) < 0|| parseFloat(this.priceGuideOffline.kind5)< 0 || parseFloat(this.priceGuideOffline.kind6) < 0|| parseFloat(this.priceGuideOffline.kind7)< 0 || parseFloat(this.priceGuideOffline.kind8)< 0 || parseFloat(this.priceGuideOffline.kind9)< 0 || parseFloat(this.priceGuideOffline.kind10)< 0){
        toast.error("金额不能为负数!")
        return false
      }
        store.dispatch('priceguideoffline/save', this.priceGuideOffline).then(res => {
          if (res.data.code == 0) {
            toast.success("保存成功!")
            refetchData()
            this.$refs['modalPriceGuideOffline'].hide()
          } else {
            toast.error(res.data.data)
            this.$refs['modalPriceGuideOffline'].hide()
          }
        })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = priceguideofflineUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
      advanced_search,
      fromChildren,

      show,
      save,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
